<template>
  <el-card class="mt-1">
    <template #header>
      <h2 class="heading">
        {{ $t('page_profile_volunteer.my_account.title') }}
      </h2>
    </template>
    <el-descriptions :column="1">
      <el-descriptions-item
          :label="$t('page_profile_volunteer.my_account.labels.donations_raised')"
      >
        <div class="d-flex align-center">
            <span class="font-bold">
            {{ formatMoneyContent(balanceInfo.totalAmountCount) }}
          </span>
          <el-tooltip placement="top" class="balance-info-tooltip">
            <v-icon color="#3f9eff" name="info"></v-icon>
            <template #content>
              <span v-html="$t('page_profile_volunteer.my_account.labels.donations_tooltip')"></span>
            </template>
          </el-tooltip>
        </div>
      </el-descriptions-item>
      <el-descriptions-item
          :label="$t('page_profile_volunteer.my_account.labels.current_balance')"
      >
          <span class="font-bold">
            {{ formatMoneyContent(balanceInfo.currentBalance) }}
          </span>
      </el-descriptions-item>
      <el-descriptions-item
          :label="$t('page_profile_volunteer.my_account.labels.total_charges')"
      >
          <span class="font-bold">
            {{ formatMoneyContent(balanceInfo.totalCharges) }}
          </span>
      </el-descriptions-item>
      <el-descriptions-item
          :label="$t('page_profile_volunteer.my_account.labels.admin_operations')"
      >
          <span class="font-bold">
            {{ formatMoneyContent(balanceInfo.totalAdminOperations) }}
          </span>
      </el-descriptions-item>
    </el-descriptions>
  </el-card>
</template>

<script>
import {fetchVolunteerAccountBalance} from '../../services/profile-volunteer-service'
import {moneyFormat} from '../../../shared/utils/moneyFormat'
import VIcon from '../../../components/ui/v-icon/VIcon'

export default {
  name: 'VolunteerBalance',
  components: {VIcon},
  props: {
    volunteerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      balanceInfo: {
        currentBalance: 0,
        totalCharges: 0,
        totalAdminOperations: 0,
      }
    }
  },
  created() {
    this.fetchBalanceInfo()
  },
  methods: {
    formatMoneyContent(val) {
      return moneyFormat(val, {
        maximumFractionDigits: 2,
      })
    },
    fetchBalanceInfo() {
      this.isLoading = true
      fetchVolunteerAccountBalance(this.volunteerId)
          .then(res => {
            this.balanceInfo = res
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: `${this.$t('page_profile_volunteer.my_account.messages.fetch_error')}`
            })
          })
          .finally(() => {
            this.isLoading = false
          })
    }
  }
}
</script>

<style scoped>
.balance-info-tooltip {
  margin-left: 0.2rem;
}
</style>
