import {api} from '../../shared/services/backend-api'

const RESOURCE = 'volunteers'

const fetchVolunteerAccountBalance = async (volunteerId) => {
  const { data } = await api.get(`${RESOURCE}/${volunteerId}/balance`)
  
  const {
    totalAmountCount,
    totalOperationsCount: currentBalance,
    paymentOperationsCount: totalCharges,
    adminOperationsCount: totalAdminOperations
  } = data
  
  return {
    currentBalance,
    totalAmountCount,
    totalCharges,
    totalAdminOperations,
  }
}
const fetchVolunteerBalanceHistory = async (volunteerId) => {
  const { data } = await api.get(`${RESOURCE}/${volunteerId}/balance-history`)
  const balanceHistory = data.data.map(
    ({
      balanceOperationID: operationId,
      title,
      type,
      price,
      balance,
      createdAt: transactionDate
     }) => ({
      operationId,
      title,
      type,
      price,
      balance,
      transactionDate,
    })
  )
  return balanceHistory
}


export {
  fetchVolunteerAccountBalance,
  fetchVolunteerBalanceHistory
}
