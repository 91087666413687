<template>
  <div v-loading="isLoading">
    <VolunteerInfo :volunteer-code="volunteerCode"/>
    <VolunteerBalance :volunteer-id="userID" />
    <VolunteerBalanceHistory :volunteer-id="userID" />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import VolunteerInfo from './volunteer-info/VolunteerInfo'
import VolunteerBalance from './volunteer-balance/VolunteerBalance'
import VolunteerBalanceHistory from './volunteer-balance-history/VolunteerBalanceHistory'

export default {
  name: 'ProfileVolunteer',
  components: {VolunteerBalanceHistory, VolunteerBalance, VolunteerInfo},
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState('authState', {
      volunteerCode: state => state.user.referenceCode,
      userID: state => state.user.uuid,
    })
  }
}
</script>

<style scoped>

</style>
