<template>
  <el-card class="mt-1">
    <template #header>
      <h2 class="heading">
        {{ $t('page_profile_volunteer.my_id.title') }}
      </h2>
    </template>
    <el-descriptions :column="1">
      <el-descriptions-item
          :label="$t('page_profile_volunteer.my_id.labels.volunteer_id')"
      >
          <span class="font-bold">
            {{ volunteerCode }}
          </span>
      </el-descriptions-item>
    </el-descriptions>
  </el-card>
</template>

<script>
export default {
  name: 'VolunteerInfo',
  props: {
    volunteerCode: {
      type: String,
      required: true
    }
  }
}
</script>
