<template>
  <el-card class="mt-1">
    <template #header>
      <h2 class="heading">
        {{ $t('page_profile_volunteer.account_history.title') }}
      </h2>
    </template>
    <el-table
        :default-sort="{prop: 'transactionDate', order: 'descending'}"
        :data="balanceHistoryToShow"
        @sort-change="customSort"
    >
      <el-table-column
          sortable="custom"
          prop="title"
          :width="300"
          :label="$t('page_profile_volunteer.account_history.table_headers.title')"
      >
        <template #default="{row}">
          <div class="font--small">
            {{ row.title }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
          sortable
          prop="type"
          :width="200"
          :label="$t('page_profile_volunteer.account_history.table_headers.operation_type')"
      >
        <template #default="scope">
          {{ $t(`page_profile_volunteer.account_history.operation_types.${scope.row.type}`) }}
        </template>
      </el-table-column>
      <el-table-column
          sortable
          prop="price"
          :width="200"
          class-name="text-right"
          :label="$t('page_profile_volunteer.account_history.table_headers.amount')"
      >
          <template #default="{row}">
            {{ formatMoneyContent(row.price) }}
          </template>
      </el-table-column>
      <el-table-column
          sortable
          prop="balance"
          :width="200"
          class-name="text-right"
          :label="$t('page_profile_volunteer.account_history.table_headers.balance')"
      >
        <template #default="{row}">
            {{ formatMoneyContent(row.balance) }}
        </template>
      </el-table-column>
      <el-table-column
          sortable
          prop="transactionDate"
          :width="120"
          :label="$t('page_profile_volunteer.account_history.table_headers.transaction_date')"
      >
        <template #default="{row}">
          {{ row.transactionDate.slice(0, 10) }}
        </template>
      </el-table-column>
    </el-table>
    <v-pagination
        :items="balanceHistory"
        @update:items-to-show="balanceHistoryToShow = $event"
    />
  </el-card>
</template>

<script>
import {fetchVolunteerBalanceHistory} from '../../services/profile-volunteer-service'
import VPagination from '../../../components/ui/v-pagination/VPagination'
import {moneyFormat} from '../../../shared/utils/moneyFormat'
import {tableSort} from '../../../shared/utils/tableSort'

export default {
  name: 'VolunteerBalanceHistory',
  components: {VPagination},
  props: {
    volunteerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      balanceHistory: [],
      balanceHistoryToShow: []
    }
  },
  created() {
    this.fetchBalanceHistory()
  },
  methods: {
    formatMoneyContent(val) {
      return moneyFormat(val, {
        maximumFractionDigits: 2,
      })
    },
    customSort(sortInfo) {
      this.balanceHistory = tableSort(this.balanceHistory, sortInfo)
    },
    fetchBalanceHistory() {
      this.isLoading = true
      fetchVolunteerBalanceHistory(this.volunteerId)
        .then(res => {
          this.balanceHistory = res
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `${this.$t('page_profile_volunteer.account_history.messages.fetch_error')}`
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
